import { extendTheme } from '@chakra-ui/react';
import { HEADING_FONT, BODY_FONT, BODY_ALTERNATIVE, CUSTOM_FONT } from '@/constants/fonts';
import {
  BG_ALTERNATIVE,
  BG_PRIMARY,
  BG_SECONDARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_ALTERNATIVE,
  BG_OPACITY,
} from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://cha
 kra-ui.com/guides/using-fonts
 */
const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    CustomFont: CUSTOM_FONT,
    heading: HEADING_FONT,
    cursive: HEADING_FONT,
    body: BODY_FONT,
    bodyAlternative: BODY_ALTERNATIVE,
    serif: BODY_FONT,
    // for generator purpose
    jost: `'Jost', sans-serif;`,
  },
  colors: {
    bgPrimary: 'rgba(212,192,182,1)',
bgSecondary: 'rgba(160,87,70,1)',
bgAlternative: '#FFFFFF',
bgOpacity: '#323030',
mainColorText: 'rgba(69,20,41,1)',
secondaryColorText: 'rgba(69,20,41,1)',
alternativeColorText: '#FEFEFE',
    transparent: 'transparent',
    theme: {
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
    },
  },
  // override components
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'normal',
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'bodyAlternative',
      },
    },
  },
};

export default extendTheme(theme);
