// Mood Board Color
export const BG_PRIMARY = '#D5DADE';
export const BG_SECONDARY = '#737373';
export const BG_ALTERNATIVE = '#FFFFFF';
export const BG_OPACITY = '#323030';

// Text Color
export const TEXT_PRIMARY = '#2C3F4E';
export const TEXT_SECONDARY = '#737373';
export const TEXT_ALTERNATIVE = '#FEFEFE';

// Music & Navigation Color
export const NAVIGATION_COLOR = '#737373'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
